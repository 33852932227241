import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, getFormSyncErrors, InjectedFormProps } from "redux-form";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { HelpLink } from "../../app/components/HelpLink";
import { resetTwoFactorRequest } from "../duck/actions";
import { required } from "@teslagov/clarakm-js";
import { t } from "i18next";

const TWO_FACTOR_RESPONSE_FORM = {
  NAME: "two-factor-response-form",
  FIELDS: {
    CODE: "code",
    TRUST: "trustDevice",
  },
};

class TwoFactorForm extends Component<Props, State> {
  requiredValidator = required();
  renderField = props => {
    const { disabled, input, label, type, ...additional } = props;
    const { touched, error } = props.meta;
    const isCheckboxOrRadio = type === "radio" || type === "checkbox";
    const formElementClassNames = isCheckboxOrRadio ? "" : "form-control clarakm-input";
    const formElement = <input className={formElementClassNames} disabled={disabled} id={input.name} {...input} type={type} {...additional} />;
    const errorClass = touched ? "text-danger" : "text-muted";
    let color;

    if (touched && !error && !disabled) {
      color = "success";
    }
    else if (touched && error && !disabled) {
      color = "danger";
    }

    return (
      <div color={color} className="form-group">
        {!isCheckboxOrRadio && (
          <React.Fragment>
            <label htmlFor={input.name}>{label}</label>
            {formElement}
          </React.Fragment>
        )}

        {isCheckboxOrRadio && (
          <label htmlFor={input.name}>
            {formElement} {label}
          </label>
        )}

        {!disabled && (error && <span className={errorClass}>{error?.message ?? error}</span>)}
      </div>
    );
  };

  render() {
    const { submitting, formErrors, handleSubmit, resetTwoFactor, hasFailedV3Captcha, captchaV2Token } = this.props;
    const { requiredValidator } = this;
    const disabled = !isEmpty(formErrors) || submitting || (hasFailedV3Captcha && !captchaV2Token);

    return (
      <React.Fragment>
        {!submitting && (
          <React.Fragment>
            <p>Please enter the access code that you received.</p>

            <form onSubmit={handleSubmit} className="mt-2">
              <Field name={TWO_FACTOR_RESPONSE_FORM.FIELDS.CODE} type="text" label="Access Code" autoComplete="off" component={this.renderField} validate={requiredValidator} />

              <p>If the device you're using is your own, would you like to skip this step in the future by trusting this device?</p>
              <Field name={TWO_FACTOR_RESPONSE_FORM.FIELDS.TRUST} type="checkbox" label="Trust this device." autoComplete="off" component={this.renderField} />

              <button type="submit" disabled={disabled} className="btn btn-primary">
                <span className="mx-auto">
                  {t("signIn.actions.signIn")}
                </span>
              </button>
            </form>

            <div className="d-flex justify-content-between mt-4">
              <button className="btn btn-link" onClick={resetTwoFactor}>
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                {t("actions.goBack")}
              </button>

              <HelpLink />
            </div>
          </React.Fragment>
        )}
        {submitting && <div className="text-center text-dark">Logging in, please wait...</div>}
      </React.Fragment>
    );
  }
}

type Props = MyProps & InjectedProps & InjectedActions & InjectedFormProps<Record<string, unknown>>;

type MyProps = {
  captchaV2Token: string;
  hasFailedV3Captcha: boolean;
  storeCaptchaV2Token: (token: string) => void;
}

type InjectedProps = {
  formValues: any;
  formErrors: any;
};

type InjectedActions = {
  resetTwoFactor: typeof resetTwoFactorRequest;
};

type State = Record<string, unknown>;

const mapStateToProps = state => {
  return {
    formValues: getFormValues(TWO_FACTOR_RESPONSE_FORM.NAME)(state),
    formErrors: getFormSyncErrors(TWO_FACTOR_RESPONSE_FORM.NAME)(state),
  };
};

const mapDispatchToProps = {
  resetTwoFactor: resetTwoFactorRequest,
};

export default reduxForm<any, any>({
  form: TWO_FACTOR_RESPONSE_FORM.NAME, // a unique identifier for this form
  enableReinitialize: true,
  initialValues: { email: "" },
  shouldValidate: () => true,
})(
  connect<InjectedProps, InjectedActions>(
    mapStateToProps,
    mapDispatchToProps
  )(TwoFactorForm)
);
