import { publicClarakmConfig as clarakmConfig, MaintenanceMode } from "@teslagov/clarakm-env-js";
import {
  ApiResponse,
  DefaultLanguageCode,
  executeSsoLoginEndpoints,
  getLanguageFromLocalStorage,
  getTokenExpirationTime,
  Loading,
  LogoutReason,
  profileActions,
  profileApi,
  Self,
  setTitle, setTokenExpirationTime, tokenAPI
} from "@teslagov/clarakm-js";
import classNames from "classnames";
import { t } from "i18next";
import { parse } from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import BrowserSupportWrapper from "../../app/components/BrowserSupportWrapper";
import { APP_KEY, claraToastr, LOGIN_TABS, RAMPART_API } from "../../app/constants";
import { doAuthorizationRedirect, getProviderForIdPRequestIssuer, OIDCState } from "../../app/oidc-utils";
import { getDeviceId, getOIDCRequestId } from "../../app/storage-util";
import Disclaimer from "../../disclaimer/Disclaimer";
import { LoginFormContainer } from "../../login";
import { getRedirectUrl } from "../../login/constants";
import { injectSecureEnv } from "../../login/duck/sagas";
import MaintenanceBanner from "../../maintenance/MaintenanceBanner";
import RequestAccountForm from "../../request-account/components/RequestAccountForm";
import { clearSelfRegistrationFailure, requestAccountRequest, storeCaptchaV2Token as storeRequestAccountCaptchaToken } from "../../request-account/duck";
import { changeTabToLogin, changeTabToRequestAccount } from "../duck";
import { isLoginTabSelectedSelector, isRequestAccountTabSelectedSelector } from "../selectors";
import jwtDecode from 'jwt-decode';

class LoginNavContainer extends Component<Props, State> {
  state: State = {
    agreedToDisclaimer: null,
    tokenExpirationTimerHandle: null,
    isOidcCallBack: false,
    language: getLanguageFromLocalStorage() ?? DefaultLanguageCode,
  };

  pollForProfile = async () => {
    const maxRetry = 3;
    const waitTime = 5_000;
    let response: ApiResponse<Self> = undefined;

    for (let i = 0; i < maxRetry; i++) {
      response = await profileApi.getSelf().catch(() => {
        if (i !== maxRetry - 1) {
          return new Promise(resolve => setTimeout(resolve, waitTime));
        }
      });

      if (response) {
        break;
      }
    }

    return response ?? Promise.reject();
  };

  componentDidMount() {
    const { disclaimer: disclaimerConfig } = clarakmConfig.login;
    const { redirectIfLoggedIn } = this;
    const { redirectTo, location, actions } = this.props;

    if (location.pathname === "/logout") {
      const reason = parse(location.search).reason as string;

      setTitle("Logout");

      actions.logout(false, reason);

      //start running a repeating timer to see if the token is reactivated
      this.setState({
        tokenExpirationTimerHandle: setInterval(() => redirectIfLoggedIn(redirectTo), 1000)
      });
    }
    else if (location.pathname === "/oidc") {
      const parsedParams = parse(location.search);
      const code = parsedParams.code;
      const deviceId = getDeviceId();

      this.setState({ isOidcCallBack: true });

      if (code !== null) {
        let state: OIDCState = null;

        try {
          state = JSON.parse(atob(parsedParams.state as string));
        }
        catch (e) {
          claraToastr.error("Login Error", "Failed to successfully parse state.");
        }

        const requestId = getOIDCRequestId();

        if (state !== null && state.requestId === requestId) {
          RAMPART_API
            .post(`/oidc/authenticate?provider=${state.provider}&code=${code}&deviceId=${deviceId}&appKey=${APP_KEY}`)
            .then(() => this.pollForProfile())
            .then(() => injectSecureEnv())
            .then(() => executeSsoLoginEndpoints())
            .then(() => tokenAPI.get())
            .then(r => {
              const jwt = r.response.jwt;

              if (jwt) {
                const decoded = jwtDecode<any>(jwt);

                setTokenExpirationTime(decoded.expMax);
              }
            })
            .then(() => {
              if (state.redirect) {
                redirectIfLoggedIn(state.redirect);
              }
              else {
                window.location.href = "/";
              }
            })
            .catch(() => claraToastr.error("Login Error", "An error occurred while trying to log you in, please contact support."));
        }
        else {
          this.setState({ isOidcCallBack: false });
          this.props.history.push("/login");
        }
      }
    }
    else if (location.pathname === "/oidc/idp") {
      const parsedParams = parse(location.search);
      const issuer = parsedParams.iss as string;

      this.setState({ isOidcCallBack: true });

      getProviderForIdPRequestIssuer(issuer)
        .then(provider => provider === undefined ? this.props.history.push("/login") : doAuthorizationRedirect(provider));
    }
    else {
      setTitle("Login");

      if (redirectTo != null) {
        redirectIfLoggedIn(redirectTo);
      }
    }

    if (location.hash.endsWith(LOGIN_TABS.signIn)) {
      actions.changeTabToLogin();
    }
    else if (location.hash.endsWith(LOGIN_TABS.requestAccount)) {
      actions.changeTabToRequestAccount();
    }

    if (disclaimerConfig.enabled && disclaimerConfig.persist) {
      this.setState({
        agreedToDisclaimer: localStorage.getItem(disclaimerConfig.persistenceKey) != null,
      });
    }
  }

  componentWillUnmount() {
    this.clearAutoRedirectInterval();
  }

  clearAutoRedirectInterval() {
    clearInterval(this.state.tokenExpirationTimerHandle);
  }

  clearAutoRedirectIntervalAndLogout() {
    this.clearAutoRedirectInterval();

    // call logout because for w/e reason we appear to have a mis-matched login state
    this.props.actions.logout(false, LogoutReason.TokenExpired);
  }

  /** Checks to see if the token is still valid. If it is, redirects to the request or default URL.*/
  redirectIfLoggedIn = (redirectTo: string) => {
    if (clarakmConfig.ups?.enabled) {
      const isTokenExpirationValid = getTokenExpirationTime() > Date.now() / 1000;

      // ensure that there is a future token expiration in local storage
      if (isTokenExpirationValid) {
        // make an API call to ensure the user has a valid token
        profileApi.getSelf().then(r => {
          if (r.status === 200) {
            this.clearAutoRedirectInterval();
            document.location.href = getRedirectUrl(redirectTo);
          }
          else {
            // login failure
            this.clearAutoRedirectIntervalAndLogout();
          }
        });
      }
    }
  };

  updateLanguage = (selectedLanguage: string) => {
    this.setState({ language: selectedLanguage });
  };

  render() {
    const { isLoginTabSelected, isRequestAccountTabSelected, actions, redirectTo } = this.props;
    const { agreedToDisclaimer, isOidcCallBack } = this.state;
    const { agreed, updateLanguage } = this;
    const { changeTabToLogin, changeTabToRequestAccount, onSubmit, goBack, storeRequestAccountCaptchaToken } = actions;
    const showDisclaimer = !agreedToDisclaimer && clarakmConfig.login.disclaimer.enabled;
    const allowRequestAccount = clarakmConfig.login.allowRequestAccount || clarakmConfig.login.selfRegistration.enabled;
    const showLoginForm = (isLoginTabSelected || !allowRequestAccount) && !isOidcCallBack;
    const showRequestAccessForm = isRequestAccountTabSelected && allowRequestAccount && !isOidcCallBack;

    return (
      <BrowserSupportWrapper>
        {clarakmConfig.app.maintenance.mode !== MaintenanceMode.Off && <MaintenanceBanner />}

        {showDisclaimer && <Disclaimer onAgreed={agreed} />}

        {!showDisclaimer && (
          <div className="card mx-auto">
            <div className="card-block">
              <>
                {allowRequestAccount && <NavTabLinks {...{ changeTabToLogin, changeTabToRequestAccount, isLoginTabSelected, isRequestAccountTabSelected }} />}

                <div className="p-4">
                  {showLoginForm && <LoginFormContainer redirectTo={redirectTo} handleLanguageChange={updateLanguage} />}

                  {showRequestAccessForm && <RequestAccountForm onSubmit={onSubmit} goBack={goBack} storeCaptchaV2Token={storeRequestAccountCaptchaToken} />}

                  {isOidcCallBack &&
                    <>
                      <div className="text-center text-dark">Logging in, please wait...</div>
                      <Loading />
                    </>
                  }
                </div>
              </>
            </div>
          </div>
        )}
      </BrowserSupportWrapper>
    );
  }

  agreed = () => {
    if (clarakmConfig.login.disclaimer.persist) {
      localStorage.setItem(clarakmConfig.login.disclaimer.persistenceKey, true.toString());
    }

    this.setState({ agreedToDisclaimer: true });
  };
}

const NavTabLinks = props => {
  const { changeTabToLogin, changeTabToRequestAccount, isLoginTabSelected, isRequestAccountTabSelected } = props;

  return (
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
        <a className={classNames("nav-link", { active: isLoginTabSelected })} data-toggle="tab" href={`#${LOGIN_TABS.signIn}`} onClick={() => changeTabToLogin()} role="tab">
          <span className="nav-text">
            {t("signIn.title")}
          </span>
        </a>
      </li>
      <li className="nav-item bg-beige">
        <a className={classNames("nav-link", { active: isRequestAccountTabSelected })} data-toggle="tab" href={`#${LOGIN_TABS.requestAccount}`} onClick={() => changeTabToRequestAccount()} role="tab">
          <span className="nav-text">
            {t("requestAccount.title")}
          </span>
        </a>
      </li>
    </ul>
  );
};

type Props = InjectedProps & InjectedActions & RouteChildrenProps & { history: History };

type InjectedProps = {
  isLoginTabSelected: boolean;
  isRequestAccountTabSelected: boolean;
  redirectTo: string;
  onSubmit: (isSelfRegister: boolean) => void;
};

type InjectedActions = {
  actions: {
    changeTabToLogin: any;
    changeTabToRequestAccount: any;
    logout: typeof profileActions.logoutRequest;
    onSubmit: (captcha: string) => any;
    goBack: () => void;
    storeRequestAccountCaptchaToken: (token: string) => void;
  };
};

type State = {
  tokenExpirationTimerHandle: NodeJS.Timeout;
  agreedToDisclaimer: boolean;
  isOidcCallBack: boolean;
  language?: string;
};

const mapStateToProps = createStructuredSelector({
  isLoginTabSelected: isLoginTabSelectedSelector,
  isRequestAccountTabSelected: isRequestAccountTabSelectedSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const params = parse(ownProps.location.search);

  return {
    actions: bindActionCreators(
      {
        changeTabToLogin,
        changeTabToRequestAccount,
        logout: profileActions.logoutRequest,
        onSubmit: requestAccountRequest,
        goBack: clearSelfRegistrationFailure,
        storeRequestAccountCaptchaToken: storeRequestAccountCaptchaToken
      },
      dispatch
    ),
    // NGINX uses `return_url` while we prefer `redirect` -- support both
    redirectTo: (params.return_url || params.redirect) as string,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginNavContainer);
