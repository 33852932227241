import React, { PureComponent } from "react";
import { clarakmConfig, MaintenanceMode } from "@teslagov/clarakm-env-js";
import Title from "../app/components/Title";
import { setTitle } from "@teslagov/clarakm-js";

export const DEFAULT_MESSAGES = [ `${clarakmConfig.app.title} is currently undergoing scheduled maintenance.`, `We apologize for the inconvenience and appreciate your patience as we work to improve ${clarakmConfig.app.title}.` ];

class MaintenanceComponent extends PureComponent<Record<string, unknown>, Record<string, unknown>> {
  componentDidMount() {
    setTitle("Maintenance");
    this.ensureEnabled();
  }

  componentDidUpdate() {
    this.ensureEnabled();
  }

  ensureEnabled() {
    if (clarakmConfig.app.maintenance.mode !== MaintenanceMode.On) {
      window.history.replaceState(window.history.state, null, "/login");
    }
  }

  render() {
    return (
      <div className="px-5">
        <Title />

        {this.getMessage()}
      </div>
    );
  }

  getMessage() {
    const messages = clarakmConfig.app.maintenance.messages || DEFAULT_MESSAGES;

    return messages.map((o, i) => <p key={`maintenance-message_${i}`}>{o}</p>);
  }
}

export default MaintenanceComponent;
