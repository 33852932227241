import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { isInternalUrl, isLocalhostUrl } from "@teslagov/clarakm-js";

export const NAME = "login-request";

export const LOGIN_FORM_NAME = "login-form";
export const TWO_FACTOR_REQUEST_FORM_NAME = "two-factor-request-form";
export const TWO_FACTOR_RESPONSE_FORM_NAME = "two-factor-response-form";

export function getRedirectUrl(redirectTo: string) {
  const isValidRedirectUrl = redirectTo != null && (isInternalUrl(redirectTo) || isLocalhostUrl(redirectTo));

  return (isValidRedirectUrl ? redirectTo : clarakmConfig.login.defaultRedirectUrl) || "/";
}
