import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { HelpLink } from "../app/components/HelpLink";
import { CAPTCHA_ACTIONS, captchaInfoToastr, claraToastr } from "../app/constants";
import { Api } from "./Api";
import { ForgotUsernameForm, ForgotUsernameFormData } from "./ForgotUsernameForm";
import { Title } from "@teslagov/clarakm-js";
import { publicClarakmConfig as clarakmConfig } from "@teslagov/clarakm-env-js";
import { executeCallbackWithCaptchaIfNecessary } from "../app/utils";

class ForgotUsernameContainer extends Component<Props, State> {
  state: State = {
    isBusy: false,
    isSubmitted: false,
    hasFailedV3Captcha: false,
    captchaV2Token: undefined,
  };

  handleSubmit = (formData: ForgotUsernameFormData) => {
    const { email } = formData;
    const { hasFailedV3Captcha, captchaV2Token } = this.state;
    const onSubmit = (token: string) => Api.sendForgotUsernameEmail(email, token, hasFailedV3Captcha)
      .then(() => {
        this.setState({ isSubmitted: true });
      })
      .catch(error => {
        if (error.respsonse.status === 403) {
          captchaInfoToastr();
          this.setState({ hasFailedV3Captcha: true });
        }
        else {
          claraToastr.apiError(error);
        }
      })
      .finally(() => this.setState({ isBusy: false }));

    this.setState({ isBusy: true });
    executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.forgotUsername, onSubmit, hasFailedV3Captcha, captchaV2Token);

  };

  render() {
    const { isBusy, isSubmitted, hasFailedV3Captcha, captchaV2Token } = this.state;

    return (
      <div className="card mx-auto p-4">
        <Title value="Forgot Username" />
        <div className="card-block">
          {!isSubmitted && (
            <React.Fragment>
              <h4 className="card-title">Forgot Username</h4>

              <p>In order to recover your username, please enter your email address below.</p>

              <ForgotUsernameForm
                isBusy={isBusy}
                onSubmit={this.handleSubmit}
                hasFailedV3Captcha={hasFailedV3Captcha}
                captchaV2Token={captchaV2Token}
                storeCaptchaV2Token={(captchaV2Token: string) => this.setState({ captchaV2Token })}
              />
            </React.Fragment>
          )}
          {isSubmitted && (
            <React.Fragment>
              <h4 className="mb-3">Username Recovery Email Sent!</h4>

              <p>You will receive an email containing your username.</p>

              <span>
                If you do not receive an email within a few hours, it means that you have typed your email address incorrectly or provided an email address that is not associated with an account. In this case, you will need to <a href={`mailto:${clarakmConfig.app.helpEmail}`}>contact us</a> for assistance. We will respond to your request as soon as possible.
              </span>

              <div className="text-center mt-4 mb-3">
                <button onClick={() => this.props.history.push("/login")} className="btn btn-primary">
                  Login
                </button>
              </div>
            </React.Fragment>
          )}

          <div className="d-flex justify-content-between mt-4">
            {!isSubmitted && (
              <Link to="/login">
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                Go Back
              </Link>
            )}
            {isSubmitted && (
              <button className="btn btn-link btn-link-sm p-0 text-muted" onClick={() => this.setState({ isSubmitted: false })}>
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                Go Back
              </button>
            )}
            <HelpLink />
          </div>
        </div>
      </div>
    );
  }
}

type Props = RouteChildrenProps;

type State = {
  isBusy: boolean;
  isSubmitted: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
};

export default ForgotUsernameContainer;
