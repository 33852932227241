import { clarakmConfig, OTHER_REGISTRATION_REASON_VALUE } from "@teslagov/clarakm-env-js";
import {
  required,
  TermsOfServiceModal
} from "@teslagov/clarakm-js";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { Errors } from "../../app/components/Errors";
import { CAPTCHA_ACTIONS } from "../../app/constants";
import { executeCallbackWithCaptchaIfNecessary } from "../../app/utils";
import { Captcha } from "../../authorize/Captcha";
import {
  isEmail,
  renderCountrySelect,
  renderField,
  renderLanguageSelect,
  renderRegistrationReasonSelect,
  renderTextArea
} from "../../redux-form";
import { FORM_FIELD_NAMES, FORM_NAME } from "../constants";
import {
  captchaV2TokenSelector,
  hasFailedV3CaptchaSelector,
  isSelfRegistrationFailedSelector,
  loadingSelector
} from "../selectors";

class RequestAccountForm extends Component<Props, State> {
  state: State = {
    isTosModalOpen: false,
  };

  requiredValidator = required();

  handleSubmit = event => {
    const { onSubmit, captchaV2Token, hasFailedV3Captcha } = this.props;

    event.preventDefault();

    return executeCallbackWithCaptchaIfNecessary(CAPTCHA_ACTIONS.helpRequest, onSubmit, hasFailedV3Captcha, captchaV2Token);
  };

  updateRegistrationReason = value => {
    this.props.dispatch(change(FORM_NAME, FORM_FIELD_NAMES.registrationReason, value));
  };

  render() {
    const { errors, submitting, valid, hasFailedV3Captcha, captchaV2Token, storeCaptchaV2Token, registrationReason } = this.props;
    const { isTosModalOpen } = this.state;
    const { handleSubmit, requiredValidator, } = this;
    const toggleTosModal = () => this.setState({ isTosModalOpen: !this.state.isTosModalOpen });
    const disabled = !valid || submitting || (hasFailedV3Captcha && !captchaV2Token);

    return (
      <React.Fragment>
        {errors && <Errors errors={errors} />}

        <form onSubmit={handleSubmit} >
          <Field<any> maxLength={255} name={FORM_FIELD_NAMES.firstName} fieldName={FORM_FIELD_NAMES.firstName} label={t("labels.firstName")} validate={requiredValidator} component={renderField} inputClassnames="form-control" labelClassnames="required" classNames="form-group" autoFocus={true} />
          <Field<any> maxLength={255} name={FORM_FIELD_NAMES.lastName} fieldName={FORM_FIELD_NAMES.lastName} label={t("labels.lastName")} validate={requiredValidator} component={renderField} inputClassnames="form-control" labelClassnames="required" classNames="form-group" />
          <Field<any> maxLength={255} name={FORM_FIELD_NAMES.emailAddress} fieldName={FORM_FIELD_NAMES.emailAddress} label={t("labels.officialEmail")} validate={[ requiredValidator, isEmail ]} component={renderField} inputClassnames="form-control" labelClassnames="required" classNames="form-group" />
          <Field<any> name={FORM_FIELD_NAMES.citizenship} label={t("labels.citizenship")} component={renderCountrySelect} validate={requiredValidator} classNames="form-group" inputClassnames="form-control" labelClassnames="required" />
          {clarakmConfig.app.translation.enabled &&
            <Field<any> name={FORM_FIELD_NAMES.language} component={renderLanguageSelect} />
          }
          <Field<any> maxLength={255} name={FORM_FIELD_NAMES.reason} fieldName={FORM_FIELD_NAMES.reason} label={t("labels.justification")} validate={requiredValidator} component={renderTextArea} classNames="form-group" inputClassnames="form-control p-1" labelClassnames="required" rows={5} />

          {clarakmConfig.login.selfRegistration?.enabled && (
            <React.Fragment>
              <Field<any> name={FORM_FIELD_NAMES.registrationReason} fieldName={FORM_FIELD_NAMES.registrationReason} label={t("labels.regReason", { appTitle: clarakmConfig.app.title })} validate={requiredValidator} component={renderRegistrationReasonSelect} classNames="form-group" inputClassnames="form-control" labelClassnames="required" onUpdate={this.updateRegistrationReason} />
              {registrationReason === OTHER_REGISTRATION_REASON_VALUE && (
                <Field<any> maxLength={255} name={FORM_FIELD_NAMES.specifiedRegistrationReason} fieldName={FORM_FIELD_NAMES.specifiedRegistrationReason} label={t("labels.regReasonSpecific")} validate={requiredValidator} component={renderField} inputClassnames="form-control" labelClassnames="required" classNames="form-group" />
              )}
            </React.Fragment>
          )}

          <Captcha hasFailedV3Captcha={hasFailedV3Captcha} storeCaptchaV2Token={storeCaptchaV2Token} />
        </form>
        <div className="d-flex justify-content-center py-4">
          <button onClick={handleSubmit} disabled={disabled} type="submit" className="btn btn-primary">
            {t("selfRegistration.actions.sendRequest")}
          </button>
        </div>

        <div className="w-100 text-center">
          <button onClick={toggleTosModal} className="btn btn-link text-primary">
            {t("actions.tos")}
          </button>
        </div>

        <TermsOfServiceModal isOpen={isTosModalOpen} toggle={toggleTosModal} />
      </React.Fragment>
    );
  }
}

type State = {
  isTosModalOpen: boolean;
}

type Props = {
  onSubmit: (captcha: string) => any;
  goBack: () => void;
  errors: any[];
  submitting: boolean;
  handleSubmit: any;
  valid: any;
  email: string;
  registrationReason: string;
  dispatch: (dispatch: any) => void;
  isSelfRegistrationFailed: boolean;
  hasFailedV3Captcha: boolean;
  captchaV2Token: string;
  storeCaptchaV2Token: (token: string) => void;
};

const mapStateToProps = state => ({
  email: formValueSelector(FORM_NAME)(state, FORM_FIELD_NAMES.emailAddress),
  registrationReason: formValueSelector(FORM_NAME)(state, FORM_FIELD_NAMES.registrationReason),
  isSelfRegistrationFailed: isSelfRegistrationFailedSelector(state),
  submitting: loadingSelector(state),
  hasFailedV3Captcha: hasFailedV3CaptchaSelector(state),
  captchaV2Token: captchaV2TokenSelector(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default reduxForm<any, any>({
  form: FORM_NAME,
  shouldValidate: () => true,
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestAccountForm)
);
