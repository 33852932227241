import { clarakmConfig } from "@teslagov/clarakm-env-js";
import { DefaultLanguageCode, LanguageDropdown, getLanguageFromLocalStorage, i18n, required, setLanguageInLocalStorage } from "@teslagov/clarakm-js";
import classNames from "classnames";
import { t } from "i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, getFormSyncErrors, getFormValues, reduxForm } from "redux-form";
import { HelpLink } from "../../app/components/HelpLink";
import { doAuthorizationRedirect } from "../../app/oidc-utils";
import { isEmail } from "../../redux-form";
import { isLoggedInSelector } from "../duck/selectors";
import { ProviderResource } from "./LoginContainer";

const LOGIN_FORM = {
  NAME: "login-form",
  FIELDS: {
    EMAIL: "email",
    USERNAME: "username",
    PASSWORD: "password",
  },
};

class LoginForm extends Component<Props, State> {
  renderProviderButtons = true;
  requiredValidator = required();
  state: State = {
    language: getLanguageFromLocalStorage() ?? DefaultLanguageCode,
  };

  renderField = props => {
    const { disabled, input, label, type, ...additional } = props;
    const { touched, error } = props.meta;
    const errorClass = !disabled && error ? "visible" : "invisible";
    const textClass = touched ? "text-danger" : "text-muted";
    let color;

    if (touched && !error && !disabled) {
      color = "success";
    }
    else if (touched && error && !disabled) {
      color = "danger";
    }

    return (
      <div color={color} className="form-group">
        <label htmlFor={input.name}>{label}</label>
        <input className="form-control" disabled={disabled} id={input.name} {...input} type={type} {...additional} />
        <span className={classNames(textClass, errorClass)}>{error?.message ?? error}</span>
      </div>
    );
  };

  render() {
    const { language } = this.state;
    const { submitting, handleSubmit, isLoggedIn, providers,handleLanguageChange } = this.props;
    const { requiredValidator, renderForgotPasswordOrUsernameLinks, renderProviderButtons } = this;
    const translationsEnabled = clarakmConfig.app.translation.enabled;

    return (
      <>
        {!isLoggedIn && (
          <>
            {!submitting && (
              <form onSubmit={handleSubmit}>
                {clarakmConfig.login.allowLoginWithEmail && (
                  <Field name={LOGIN_FORM.FIELDS.EMAIL} type="email" label={t("labels.emailAddress")} autoComplete="username" autoFocus={true} component={this.renderField} validate={[ requiredValidator, isEmail ]} />
                )}

                {clarakmConfig.login.allowLoginWithUsername && (
                  <Field name={LOGIN_FORM.FIELDS.USERNAME} type="text" label={t("labels.username")} autoComplete="username" autoFocus={true} component={this.renderField} validate={requiredValidator} />
                )}

                <Field name={LOGIN_FORM.FIELDS.PASSWORD} type="password" label={t("labels.password")} autoComplete="current-password" component={this.renderField} validate={requiredValidator} />

                <div className="text-center">
                  <div className="col-6 mx-auto">
                    <button type="submit" disabled={submitting} className="btn btn-primary btn-sign-in">
                      {t("signIn.actions.signIn")}
                    </button>
                  </div>

                  {providers.length !== 0 && renderProviderButtons && (
                    <div className="providers">
                      <div className="col-8 mx-auto">
                        <hr className="my-4 login-splitter" />
                      </div>

                      <div className="d-flex justify-content-center">
                        {providers.map(provider => (
                          <button key={provider.key} className="btn btn-secondary btn-sign-in mb-2 mx-2" onClick={() => doAuthorizationRedirect(provider)}>
                            {provider.displayName}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}

            {submitting && (
              <div className="text-center text-dark">
                {t("signIn.messages.signingIn")}
              </div>
            )}

            <div className="login-footer d-flex justify-content-between mt-4">
              {translationsEnabled && (
                <div className="d-flex flex-grow-1">
                  <LanguageDropdown
                    language={language}
                    options={clarakmConfig.app.translation.supportedLocales}
                    onChange={selectedLanguage => {
                      i18n.changeLanguage(selectedLanguage).then(resp => {
                        setLanguageInLocalStorage(selectedLanguage);
                        handleLanguageChange(selectedLanguage);
                        this.setState({ language: selectedLanguage });
                      });
                    }}
                  />
                </div>
              )}

              <div className={classNames('d-flex flex-grow-1', 'justify-content-between')}>
                {!submitting && renderForgotPasswordOrUsernameLinks()}
              </div>

              <div className="d-flex flex-grow-1 justify-content-end">
                <HelpLink />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  renderForgotPasswordOrUsernameLinks(): React.ReactNode {
    if (!clarakmConfig.login.allowLoginWithUsername) {
      return (
        <Link to="/forgot-password">
          {t("signIn.actions.forgotPassword")}
        </Link>
      );
    }
    else {
      return (
        <>
          Forgot <Link to={"/forgot-username"}>username</Link>
          {" or "}
          <Link to={"/forgot-password"}>password</Link>
          {"?"}
        </>
      );
    }
  }
}

type Props = MyProps & InjectedProps;

type MyProps = {
  submitting: any;
  handleSubmit: any;
  providers: ProviderResource[];
  handleLanguageChange: any;
};

type InjectedProps = {
  formValues: any;
  formErrors: any;
  isLoggedIn: boolean;
};

type State = {
  language?: string;
};

const mapStateToProps = (state): InjectedProps => {
  return {
    formValues: getFormValues(LOGIN_FORM.NAME)(state),
    formErrors: getFormSyncErrors(LOGIN_FORM.NAME)(state),
    isLoggedIn: isLoggedInSelector(state),
  };
};

const ConnectedLoginForm = connect<InjectedProps, Record<string, unknown>, MyProps>(mapStateToProps)(LoginForm);

export default reduxForm<any, any>({
  form: LOGIN_FORM.NAME, // a unique identifier for this form
  enableReinitialize: true,
  shouldValidate: () => {
    return true;
  },
})(ConnectedLoginForm);
