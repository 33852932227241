import React from "react";
import "./styles.scss";

const ErrorsAndWarnings = ({ touched, error, warning }) => {
  const errorMessage = error?.message ?? error;
  const errorClass = touched ? "text-danger" : "text-muted";

  return <div>{(errorMessage && <span className={errorClass}>{errorMessage}</span>) || (touched && warning && <span className="clarakm-form-warning">{warning}</span>)}</div>;
};

export default ErrorsAndWarnings;
