import { clarakmConfig } from "@teslagov/clarakm-env-js";
import {
  CountrySelect,
  LanguageSelect,
  Select
} from "@teslagov/clarakm-js";
import React from "react";
import ErrorsAndWarnings from "./ErrorsAndWarnings";
import Label from "./Label";
import TextArea from "./TextArea";
import TextInput from "./TextInput";
import { isEmail, maxLength, maxLength16, passWordMatch, validPassword, validResetCode } from "./validators";

const renderField = ({
  autocomplete,
  input,
  fieldName, // TODO name is undefined so for now we're passing in a fieldName prop
  classNames,
  inputDivClassnames,
  inputClassNames,
  label,
  labelDivClassnames,
  labelClassnames,
  type,
  meta: { touched, error, warning },
  autoFocus,
  maxLength,
  readOnly,
}) => {
  return (
    <div className={classNames}>
      <Label name={fieldName} label={label} labelDivClassnames={labelDivClassnames} labelClassnames={labelClassnames} />
      <TextInput autocomplete={autocomplete} maxLength={maxLength} autoFocus={autoFocus} name={fieldName} inputDivClassNames={inputDivClassnames} inputClassNames={inputClassNames} type={type} input={input} readOnly={readOnly} />
      <ErrorsAndWarnings touched={touched} error={error} warning={warning} />
    </div>
  );
};

const renderTextArea = ({
  input,
  name,
  fieldName, // TODO name is undefined so for now we're passing in a fieldName prop
  inputDivClassnames,
  inputClassNames,
  classNames,
  label,
  labelDivClassnames,
  labelClassnames,
  type,
  meta: { pristine, touched, error, warning },
  autoFocus,
  maxLength,
  rows,
}) => {
  return (
    <div className={classNames}>
      <Label name={fieldName} label={label} labelDivClassnames={labelDivClassnames} labelClassnames={labelClassnames} />
      <TextArea maxLength={maxLength} autoFocus={autoFocus} name={fieldName} inputDivClassnames={inputDivClassnames} inputClassNames={inputClassNames} input={input} rows={rows} />
      <ErrorsAndWarnings touched={touched} error={error} warning={warning} />
    </div>
  );
};

const renderCountrySelect = ({ input, classNames, label, labelDivClassnames, labelClassnames, meta: { touched, error, warning } }) => {
  return (
    <div className={classNames}>
      <Label name={input.name} label={label} labelDivClassnames={labelDivClassnames} labelClassnames={labelClassnames} />
      <CountrySelect id="country-select" value={input.value} onChange={country => input.onChange(country ? country.value : "")} />
      <ErrorsAndWarnings touched={touched} error={error} warning={warning} />
    </div>
  );
};

const renderRegistrationReasonSelect = ({ input, value, classNames, label, labelDivClassnames, labelClassnames, meta: { touched, error, warning }, onUpdate }) => {
  const defaultOption = { text: "-- please select an option --", value: "" };

  return (
    <div className={classNames}>
      <Label name={input.name} label={label} labelDivClassnames={labelDivClassnames} labelClassnames={labelClassnames} />
      <Select options={[ defaultOption, ...clarakmConfig.login.selfRegistration.registrationReasons ]} inputProps={input} value={value} onChange={value => {
        onUpdate(value[0]);
      }} />
      <ErrorsAndWarnings touched={touched} error={error} warning={warning} />
    </div>
  );
};

const renderLanguageSelect = ({ input }) => {
  return (
    <LanguageSelect
      language={input.value !== "" ? input.value : null}
      onChange={language => input.onChange(language)}
    />
  );
};

export {
  maxLength,
  renderField,
  isEmail,
  renderCountrySelect,
  renderRegistrationReasonSelect,
  renderLanguageSelect,
  renderTextArea,
  validPassword,
  passWordMatch,
  maxLength16,
  validResetCode,
};
