import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "../rootReducer";
import sagas from "../sagas";
import getHistory from "./history";
import { appSagas as clarakmSagas, APP_REDUCER_KEY as CLARAKM_REDUCER_KEY, INITIAL_APP_STATE as CLARAKM_INITIAL_APP_STATE } from "@teslagov/clarakm-js";

const sagaMiddleware = createSagaMiddleware();
const initialState = {
  [CLARAKM_REDUCER_KEY]: CLARAKM_INITIAL_APP_STATE,
};

let store;

function getStoreEnhancers() {
  const appRouterMiddleware = routerMiddleware(getHistory());
  const middleware = applyMiddleware(sagaMiddleware, appRouterMiddleware);
  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

  if (process.env.NODE_ENV === "production" || devTools == null) {
    return compose(middleware);
  }
  else {
    return compose(
      middleware,
      devTools
    );
  }
}

export default function getStore() {
  if (!store) {
    store = createStore(rootReducer, initialState, getStoreEnhancers());

    sagaMiddleware.run(sagas);
    sagaMiddleware.run(clarakmSagas);
  }

  return store;
}
